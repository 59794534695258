import React, { useEffect, useContext } from "react"
import Completed from "components/Completed"
import Layout from "layout/Layout"
import { AppContext } from "../../context/AppContext"

const FeedbackComplete = (props) => {
  const { pageContext } = props
  const { content, nextPath } = pageContext

  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    const restartDetails = async () => {
      await dispatch({ type: "RESET_DETAILS" })
    }
    restartDetails()
  }, [dispatch])

  return (
    <Layout
      title={module.title || "Thank you"}
      seoTitle={module.seoTitle}
      pageContext={pageContext}
    >
      <Completed icon={content.icon} nextPath={nextPath}>
        <p className="my-2">
          Thank you for sending your feedback! Your submission has been
          recorded.
        </p>
      </Completed>
    </Layout>
  )
}

export default FeedbackComplete

import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import classNames from "classnames"

import Container from "layout/Container"

import { useCompleteImages } from "./hooks/useCompleteImages"
const Completed = ({ children, icon, nextPath }) => {
  const data = useCompleteImages()

  return (
    <Container isCentered>
      <center>
        <Container isCentered mobile={8} tablet={6} desktop={4}>
          <figure className={classNames("image mx-3")}>
            <Img fluid={data[icon].childImageSharp.fluid} alt="Success!" />
          </figure>
        </Container>
        {children}
        <Link to={nextPath} className="button is-primary is-medium">
          Finish
        </Link>
      </center>
    </Container>
  )
}

export default Completed
